import "./App.scss";

// assets
import LogoHeader from "./assets/Logo_Positiva.svg";
import "bootstrap/dist/css/bootstrap.css";
import LinkedinFooter from "./assets/linkdin.png";
import LogoFooter from "./assets/grupo-lzt.png";
import LogoLazerturViagens from "./assets/logo-viagens.png";
import IconInsta from "./assets/icon-insta.svg";
import LogoExperience from "./assets/experience.png";
import LogoFerias from "./assets/ferias.png";
import LogoFeriasOper from "./assets/ferias-oper.png";
import LogoLztGroup from "./assets/lazertur-grupo.png";
import LogoAzul from "./assets/azul.png";
import LogoLztCorporate from "./assets/lzt-corporate.png";
import iconWhats from "./assets/whats-icon.svg";

function App() {
  interface PropsCard {
    img: any;
    text: string;
    icon: any;
    textIcon: string;
    link: string;
    background: string;
    colorText: string;
  }

  const Card: PropsCard[] = [
    {
      img: LogoLazerturViagens,
      text: "Serviços de viagens com atendimento online e presencial garantindo a melhor experiência!",
      icon: IconInsta,
      textIcon: "Acessar",
      link: "https://www.instagram.com/lazerturviagens/",
      background: "dark-blue",
      colorText: "text-white",
    },
    {
      img: LogoExperience,
      text: "Consultoria em viagens, roteiros personalizados e experiências incríveis. Nós somos o elo entre o seu desejo de viajar e a realização.",
      icon: IconInsta,
      textIcon: "Acessar",
      link: "https://www.instagram.com/lazerturexperience/",
      background: "black",
      colorText: "text-white",
    },
    {
      img: LogoFerias,
      text: "Com a Férias Fácil você tem planos diversos que cabem no seu bolso e permitem planejar sua viajem dos sonhos. Escolha onde ir, quando ir e como pagar.",
      icon: IconInsta,
      textIcon: "Acessar",
      link: "https://www.instagram.com/feriasfacil/",
      background: "linear-red",
      colorText: "text-white",
    },
    {
      img: LogoFeriasOper,
      text: "Acumulamos uma experiência de mais de 15 anos em processos de viagens, proporcionando aos seus clientes atendimento com empatia, qualidade, eficiência e compartilhamento de conhecimento, tudo de um jeito FÁCIL.",
      icon: IconInsta,
      textIcon: "Acessar",
      link: "https://www.instagram.com/ffoperadora/",
      background: "white",
      colorText: "text-blue",
    },
    {
      img: LogoLztGroup,
      text: "Dedicação e experiência para garantir toda a organização e logística desde o planejamento inicial, até a entrega com excelência, pensando em cada detalhe, esse é o nosso compromisso",
      icon: iconWhats,
      textIcon: "Acessar",
      link: "https://wa.me/+553499741504?text=Ol%C3%A1,%20tudo%20bem?%20Vim%20pela%20Landing%20Page%20do%20Grupo%20LZT.%20Quero%20conversar%20sobre%20Grupos%20e%20Eventos.",
      background: "black",
      colorText: "text-white",
    },
    {
      img: LogoAzul,
      text: "Serviços de viagens e passagens aéreas com atendimento online e presencial nas unidades localizadas em Uberlândia e Uberaba.",
      icon: IconInsta,
      textIcon: "Acessar",
      link: "https://www.instagram.com/azulviagensudi/",
      background: "light-blue",
      colorText: "text-white",
    },
    {
      img: LogoLztCorporate,
      text: "Com uma visão empreendedora embasada em três pilares: economia, tecnologia e qualidade, estamos posicionados entre os líderes do mercado Mineiro, tendo como clientes grandes grupos empresariais do estado.",
      icon: iconWhats,
      textIcon: "Acessar",
      link: "https://wa.me/+5532372515?text=Ol%C3%A1,%20tudo%20bem?%20Vim%20atrav%C3%A9s%20da%20Landing%20Page%20do%20Grupo.%20Quero%20conversar%20sobre%20a%20LZT%20Corporate.",
      background: "white",
      colorText: "text-blue",
    },
  ];

  return (
    <div className="App">
      <header className="header">
        <img src={LogoHeader} alt="Logo Grupo" className="header-img" />
      </header>

      <main className="main-background">
        <section className="container content-main">
          <h2 className="main-title">
            Um conglomerado de empresas <br /> com presença nacional.
          </h2>
          <p className="main-desc">
            Há mais de 30 anos sendo referência em{" "}
            <strong>
              serviços de viagens <br /> e segmentos agregados.
            </strong>
          </p>

          <div className="card-container">
            {Card.map((item) => (
              <div className={`container card-content ${item.background}`}>
                <div className="card-info">
                  <div className="">
                    <img src={item.img} alt="logo marca" className="card-img" />
                  </div>
                  <div>
                    <p className={`card-desc ${item.colorText}`}>{item.text}</p>
                  </div>
                </div>

                <a
                  href={item.link}
                  className="card-social"
                  target="Blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={item.icon}
                    alt="icone instagram"
                    className="icon-img"
                  />

                  <p className="link-social">{item.textIcon}</p>
                </a>
              </div>
            ))}
          </div>
        </section>
      </main>

      <footer className="footer-background">
        <section className="container footer-content">
          <a
              href="https://www.linkedin.com/company/grupolzt/"
              target="Blank"
              rel="noopener noreferrer"
            >
          <img src={LinkedinFooter} alt="logo linkedin" className="footer-linkedin" />
          </a>
          <img src={LogoFooter} alt="logo grupo" className="footer-logo" />
        </section>
      </footer>
    </div>
  );
}

export default App;
